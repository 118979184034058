import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting, AiOutlineArrowLeft} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react" 
import { Navigate, useNavigate } from 'react-router-dom';


function MiniSecAdminNav (props:any) {
 
    const navigate = useNavigate()
    let sidebar = "sidedrawer"

    if(props.toggle){
        sidebar = " sidedrawer open"
    }

      const backBtn = () => {
        navigate("/admin")
      }


   return (
  <>
      <div className={sidebar}>
          <div className=" w-full mb-6 border-b-2">
              <div className="">
                  <div className="h-20">

                  </div>
                  <div className=" ml-4">
                      <div className="p-2 flex hover:border-2 active:text-blue-500 active:rounded-md active:border-blue-500" onClick={()=> {
                          backBtn()
                      }}>
                  <AiOutlineArrowLeft className=" text-[1.5em] text-blue-500  "/>
                  <h3 className="ml-2">Back to Home</h3>
                  </div>
                  </div>
                  
              <div className=" md:flex  absolute  right-0  h-[30em] ">

              <div className="p-2 space-y-6">
                        
                        <div className="rightnav border-[1px] " 
                        onClick={ () => {
                            props.Swit()
                            props.handle()
                        }
                            }
                        >
                        <IoReceiptSharp className="righticon" />
                            <span className="p-2 text-[16px] font-serif ml-4">Add Product</span>
                       </div>
                       

                       
                        <div className="rightnav border-[1px]" 
                             onClick={ () => {
                                props.edit()
                                props.handle()
                            }
                                }
                             
                        >
                        <MdAccountBalance className="righticon" />
                            <span className="p-2 text-[16px] font-serif ml-4">Edit product</span>
                       </div>

                        <div className="rightnav border-[1px]">
                        <RiCustomerService2Fill className="righticon" />
                            <span className="p-2 text-[16px] font-serif">Mark Product</span>
                       </div>

                       <div className="rightnav border-[1px]">
                       <RiExchangeFill className="righticon" />
                          <span className="p-2 text-[16px] font-serif ml-2">Price Setting</span>
                       </div>

                       <div className="rightnav border-[1px]"
                       onClick={ () => {
                        props.category()
                        props.handle()
                    }
                        }
                       >
                       <ImHistory className="righticon " />
                            <span className="p-2 text-[16px] font-serif">Add Sort </span>
                       </div>

                   
                        
                        </div>
                        
        </div>
               

              </div>

          </div>


          
      </div>

      {/* kkks */}


      </>
   )
     
}

export default MiniSecAdminNav
