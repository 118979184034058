import axios from 'axios'
import { useState, useEffect } from 'react'
import { SmallSpinner } from './spinner'

function AddProduct(props:any) {

    const [itemName, setitemName ] = useState("")
    const [itemPrice, setitemPrice ] = useState("")
    const [itemSalePrice, setitemSalePrice ] = useState("")
    const [itemCategory, setitemCategory ] = useState("")
    const [itemQty, setitemQty ] = useState("")
    const [Sorting, setSorting]  = useState<any>([])
    const [Error, setError] = useState('')

     useEffect(() => {
        GetCatgories()

     },[Sorting])
     
      const GetCatgories = async() => {
        const res = await axios.get("https://stores.moxieus.co/getsorting")
        setSorting(res.data.data)
      }
    const handleSubmit = async() => {
         const createdby = "admin"
      const data = { itemName, itemPrice, itemSalePrice,itemQty, itemCategory, createdby}
      if(itemName === "" || itemCategory=== "" || itemPrice  === "" || itemSalePrice === "" || itemQty  === ""){
        setError("fill all field before submission ")
    }else{
      
      try {
          // const token = Cookies.get(TOKEN_STORAGE_KEY);
          // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
          
          if(parseInt(itemSalePrice) > parseInt(itemPrice)){
              setError('')
            const res =  await axios.post("https://stores.moxieus.co/add-items", data);
            if (res.status === 200) {
              
                console.log(res)
              }
          }else{
              setError("Sale Price can be less than cost price")
          }
      } catch (error) {

          // if (response.status === 401) {
             
          // }
    }
  }
    }
    

   
     const loading = true;

  return (
      <div className="w-full h-[100%] backdrop fixed top-0 bottom-0 left-0" >

          <div className='md:ml-[50em] md:mt-[20px] ml-[20em] mt-[2em] absolute bg-white rounded-full w-[30px] h-[30px] items-center  hover:bg-blue-600 hover:text-white text-red-500' onClick={props.setSwit} >
             <span className='text-[22px]  ml-2'>X</span>
          </div>
          <div className="md:w-[30em] md:mt-[50px] mt-[5em]  bg-white md:ml-[20em] mx-4 md:mx-0 rounded-lg  ">
              <h3 className='ml-10 mt-10'>Add Product </h3>
              <div className="max-w-md w-full mx-auto mt-4 bg-white p-4 border border-gray-300">
                    <div  className="space-y-4">
                        <div className=''>
                            <p className="text-red-600"> {Error} </p>
                        </div>
                    <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Item Name</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1"  onChange={(e) => setitemName(e.target.value)} required />
                        </div>
                        

                        <div>
                           
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Sort Type</label>
                            <select className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemCategory(e.target.value)}>
                            <option value="null">Select Categories</option>
                                                        {
                                    Sorting.map((item:any) => 
                                        
                                        <option value={item.id}>{item.name}</option>
                                    )
                                }
                            
                              
                            </select>
                          
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Cost Price</label>
                            <input type="number" name="Phone" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemPrice(e.target.value)}  required/>
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Sale price</label>
                            <input type="number" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemSalePrice(e.target.value)}  required/>
                        </div>
                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Quantity</label>
                            <input type="number" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemQty(e.target.value)}  required/>
                        </div>
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={handleSubmit}>Add product
                            {/* <SmallSpinner show={loading} /> */}
                            </button>
                        </div>

                    </div>
                </div>

          </div>
          <div className='h-10'>

          </div>

      </div>
  )
}

export default AddProduct