import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react" 


function MiniAdminFirstNav (props:any) {


    let sidebar = "sidedrawer"

    if(props.toggle){
        sidebar = " sidedrawer open"
    }

   return (
  <>
      <div className={sidebar}>
          <div className=" w-full mb-6 border-b-2">
              <div className="">
                  <div className="h-20">

                  </div>
                  
              <div className=" md:flex  absolute  right-0  h-[30em] ">
            <div className="p-2 space-y-6">
                            <a href="/admin/mgt" onClick={props.handle}>
                            <div className="rightnav border-[1px]">
                            <IoReceiptSharp className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Stock Management</span>
                           </div>
                           </a>

                           
                            <div className="rightnav border-[1px]" onClick={ () => {
                                props.adduser()
                                props.handle()
                            }
                                } >
                            <MdAccountBalance className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Create user</span>
                           </div>

                            <div className="rightnav border-[1px]" onClick={props.handle}>
                            <RiCustomerService2Fill className="righticon" />
                                <span className="p-2 text-[16px] font-serif">Customer Desk</span>
                           </div>

                           <div className="rightnav border-[1px]" onClick={props.handle}>
                           <RiExchangeFill className="righticon" />
                              <span className="p-2 text-[16px] font-serif ml-2">Transaction</span>
                           </div>

                           <div className="rightnav border-[1px]" onClick={props.handle}>
                           <ImHistory className="righticon " />
                                <span className="p-2 text-[16px] font-serif">Sale History </span>
                           </div>

                           <div className="rightnav border-[1px]" onClick={props.handle}>
                           <AiFillSetting className="righticon " />
                                <span className="p-2 text-[16px] font-serif ml-4">Preference </span>
                           </div>
                            
                            </div>
        </div>
               

              </div>

          </div>


          
      </div>

      {/* kkks */}


      </>
   )
     

   

}

export default MiniAdminFirstNav
