import exp from "constants";
import React from "react";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";
import SaleFront from "../Widgets/SaleFront";
import AdminFront from "../Widgets/AdminFront"
import Receipt from "../Widgets/Receipt";
import { Link, Outlet } from "react-router-dom";


 function Receiptpage () {
     return (
    
    <div>
        <Receipt />
       
        </div>
     )

}

export default Receiptpage