import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react" 


function MinMenu (props:any) {
 console.log(props)
    let sidebar = "sidedrawer"

    if(props.toggle){
        sidebar = " sidedrawer open"
    }

   return (
  
    <div className={sidebar}>
    <div className=" w-full mb-6 border-b-2">
        <div className="">
            
        <div className="p-2 space-y-6">
            <div className="mt-20">

            </div>
                
                <div className="rightnav border-[1px]" 
                onClick={ () => {
                    props.toggledown()
                    
                }
                    }

                >
                <IoReceiptSharp className="righticon" />
                    <span className="p-2 text-[16px] font-serif ml-4">Reciept</span>
               </div>

               
                <div className="rightnav border-[1px]" onClick={() => {
                    props.toggledown()
                }}>
                <MdAccountBalance className="righticon" />
                    <span className="p-2 text-[16px] font-serif ml-4">Account</span>
               </div>

                <div className="rightnav border-[1px]" onClick={() => {
                    props.toggledown()
                }}>
                <RiCustomerService2Fill className="righticon" />
                    <span className="p-2 text-[16px] font-serif">Customer Desk</span>
               </div>

               <div className="rightnav border-[1px]" onClick={() => {
                    props.toggledown()
                }}>
               <RiExchangeFill className="righticon" />
                  <span className="p-2 text-[16px] font-serif ml-2">Stock</span>
               </div>

               <div className="rightnav border-[1px]" onClick={() => {
                    props.toggledown()
                }}>
               <ImHistory className="righticon " />
                    <span className="p-2 text-[16px] font-serif">Sale History </span>
               </div>

               <div className="rightnav border-[1px]" onClick={() => {
                    props.toggledown()
                }}>
               <AiFillSetting className="righticon " />
                    <span className="p-2 text-[16px] font-serif ml-4">Preference </span>
               </div>
                
                </div>

        </div>

    </div>


    
</div>
   )
     

   

}

export default MinMenu
