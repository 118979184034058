import exp from "constants";
import { useEffect, useState } from "react";
import { MdNotificationsNone } from "react-icons/md";
import { AiFillSetting, AiOutlineLogout } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useCookies } from 'react-cookie';
import { getCookie, setCookie } from 'typescript-cookie'
import { Navigate, useNavigate } from 'react-router-dom';


 function Navbar () {

     const [dropdown , setDropdown ] = useState(false)
     const [cookies, setCookie, removeCookie] = useCookies<any>([]);
     const navigate = useNavigate()
    //   logined in 
    let loggeduser:any
     const user:any = getCookie('user')
    
      
  

      

     const dropdownMenu = () => {
         setDropdown(!dropdown)
     }

     if(user){
        loggeduser = JSON.parse(user)
      }else{
      
      }
     
       
       useEffect(()=>{
        if(user){
            loggeduser = JSON.parse(user)
          }else{
           navigate("/")
          }
       })
     return (
    
    <div  className=" shadow-lg bottom-1 h-[3em] flex bg-gray-50  border-b-2">
      {
           user ? 
           <>

<div className="border-r-2 border-gray-500 flex">
        <h2 className="text-xl text-blue-200 mt-2 ml-4 italic absolute">Opal Communication</h2>
        </div>
        <div className=" md:absolute hidden md:ml-[16em]  border-l-[2px] h-[3em]">
            <p className="text-xs mt-4 ml-6"> Welcome back {loggeduser['name']}  </p>
        </div>
        <div className="md:flex absolute   md:right-10 right-4 mt-2 space-x-2">
            <span className="md:flex hidden text-xs mt-4">{loggeduser['name']}</span>
            <div className=" rounded-full h-[2em] w-[2em] border-blue-500 border-[1px]">
                <div className="" onClick={dropdownMenu}> 
                <img  src="/images/user.png" className="rounded-full h-[2em] w-[2em]" />
                <RiArrowDropDownLine className="text-[1.3em] text-blue-500 mt-[-8px] ml-[4px]" />
                </div>
                  {/* dropdown */}
                     {
                     dropdown ? 
                     <>
                     <div className=" dropdown border-[1px] w-[7.5em] ml-[-4.6em] h-[10em] mt-[-9px] rounded-md">
                <div className="ml-10 mt-2">
                <img  src="/images/user.png" className="rounded-full h-[2em] w-[2em]" />
                    <h2 className="text-[10px] font-semibold mt-2 ml-[-20px]">{loggeduser['name']}</h2>
                    </div>
                   <div className="mt-4 ml-[2px]">
                   <div onClick={dropdownMenu} className="text-[10px] font-semibold flex rounded-md border-[1px] ">
                        <MdNotificationsNone className="text-blue-500 text-[1.5em]"/>
                        <p className="ml-2">Notification </p>
                       </div>
                       <div onClick={dropdownMenu} className=" text-[10px] font-semibold flex rounded-md border-[1px] mt-2 ">
                          
                       <AiFillSetting  className="text-[1.5em] text-blue-500" />
                       <p className="ml-2">Settings</p>
                       </div>
                   </div>
                   <a href="/">
                   <div onClick={dropdownMenu} className="flex justify-center bg-blue-500 mt-4 rounded-md">
                   <AiOutlineLogout  className="text-[1.5em] text-white" />
                   </div>
                   </a>
                   
                </div>
                     </>
                     :
                     <>
                     </>
                     }
                 {/* end dropdown */}
            </div>
            <div className="md:flex hidden">
                <MdNotificationsNone className="text-[1.5em] text-blue-500"/>
            </div>
            <AiOutlineLogout className="text-[1.5em] md:flex hidden text-blue-500" />
        </div>
    

           </>
           :
           <>
           </>

      }
        
            
        </div>
     )

}

export default Navbar