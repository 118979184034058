import exp from "constants";
import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import { SmallSpinner } from "../../Component/Layout/spinner"
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';




 function Register () {

      const [name, setName ] =  useState("");
      const [email, setEmail] = useState("")
      const [phone, setPhone ] = useState("")
      const [password, setPassword ] = useState("")
      const [err, setErr] = useState("")
      const [cookies, setCookie] = useCookies<any>([]);

      console.log(process.env.PUBLIC_API_HOST_URL, "abuchi")
      
      const loading = false;
       const navigate = useNavigate()
       const handleSubmit = async () => {
        const data = {name, email, phone, password}
          if(name === "" || email=== "" || phone  === "" || password === ""){
              setErr("fill all field before ")
          }else{
            
            try {
                // const token = Cookies.get(TOKEN_STORAGE_KEY);
                // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
                const res =  await axios.post("https://store.moxieus.tech/register", data);
                if (res.status === 200) {
                    setCookie('user', res.data.user)
                  navigate("/admin")
                }
            } catch (error) {
    
                // if (response.status === 401) {
                   
                // }
          }
        }

          console.log(data)
       }

    
     return (
    
    <div>
            <div className="max-h-screen bg-gray-50 flex-col justify-center">
                <div className="max-w-md w-full mx-auto">

                    <div className="text-3xl font-bold text-gray-900 mt-2 text-center">Opal Comm</div>
                </div>
                    <div className="">
                        <p className="text-red">{err}</p>
                    </div>

                    
                <div className="max-w-md w-full mx-auto mt-4 bg-white p-8 border border-gray-300">
                    <div  className="space-y-6">
                    <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Full Name</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1"  onChange={(e) => setName(e.target.value)} required />
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Email</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Phone Number</label>
                            <input type="number" name="Phone" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setPhone(e.target.value)}  required/>
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Password</label>
                            <input type="password" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setPassword(e.target.value)}  required/>
                        </div>
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={handleSubmit}>Sign Up <SmallSpinner show={loading} /></button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
     )

}

export default Register