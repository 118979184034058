import React from "react";
import axios from "axios";
import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react"
import {product }  from '../../items'
import Chart from '../Layout/chart'
 


type Products = typeof product



 
 function Stock (props:any) {
     
     const [items, setItems] = useState<Products>([])
     const [products, setProduct] = useState<Products>([])
     const [users, setUsers] = useState<any>([])
      
      const AddItems =(item: any) => {
           setProduct([...products,item])
      }

      const removeItem = (id: any) => {
        //   setProduct(
        //       prev => {
        //           const new_item = prev.products.filter(({id}) => _id !== id)
        //       }
        //   )
          

      } 
      let no = 1
      useEffect(() => {
         setItems(product)
         GetSalePersons()

      }
      )
  
      const GetSalePersons = async () => {
         const res = await axios.post('https://store.moxieus.tech/users')
         if(res.status === 200){
             setUsers(res.data.data)
         }     
      }

     

     return (
    
    <div>
            <div className=" mt-2 mx-2 ml-10 md:flex ">
                <div className="border-r-2 border-l-2 border-b-2 md:w-[45em] ">
                    <div className="border-b-2 flex ">
                        <h2 className="text-xl text-blue-700 md:w-80 ">[ - Data analysis -]</h2>
                    </div>
                {/* product list heading */}
             <div className="">
             <Chart />
             </div>
                 
                    
                     {/* list  item or product   */}

                   
                     <div className="md:hidden mt-10 h-40  menubtn right-4 bottom-0 fixed">
                    <div className="rounded-full text-white bg-blue-600 w-[3.5em] h-[3.5em] flex text-center " onClick={props.menus}>
               
                    <span className="text-[40px] font-bold ml-[12px] mt-[-10px]">...</span>
          
                         
                         
                    </div>

               </div>
                    

                </div>



                <div className=" mt-2  ml-2  flex space-x-2">


                    <div className="border-[1px]">
                        <div className="border-b-[1px]">
                        <h1 className="p-2">Empolyee</h1>
                        </div>

                        <div className="mt-2 p-2 overflow-auto h-[25em]  ">

                           {
                               users.length > 1 && users ? 
                               <>
                              
                                  {
                                     users.map((user:any) => 
                                     <>
                                      <div className="flex space-x-2 p-2 mt-2  rounded-md border-[1px] border-blue-500">
                                <div className=" rounded-full h-[3em] w-[3em] border-blue-500 border-[1px]">
                    <img  src="/images/user.png" className="rounded-full h-[3em] w-[3em]" />
                </div>
                <div className="">
                <div  className="text-sm mt-2">{user.name}</div>
                 <i className="text-sm ml-6">{user.address} --- {user.phone} </i>
                </div>
                                </div>
                                     </>
                                     
                                     )
                                 } 
                               </>
                               :

                              <>
                              <div className="w-60">
                               <i >No sale Rep click on add Create user to add a sale Rep</i>
                               </div>
                              </>
                           }



                        </div>

                    </div>
                  

                        

                </div>
            </div>

            


   
         {/* <div className="">
             <div className="">
                <h2> Add Product</h2>
             </div>
             <div className="">
                 <label>Product Name</label>
                 <input type="text" placeholder="product Name" />

             </div>
             
             </div>      */}

        </div>
     )

}

export default Stock