import exp from "constants";
import { useState, useEffect } from 'react'
import axios from "axios";
import { SmallSpinner } from "../../Component/Layout/spinner"
import { Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';




 function LoginForm () {

  const [password, setPassword] = useState<any>('')
  const [phone, setPhone] = useState<any>('')
  const [Err, setErr] = useState('')
  const [Huel, setHuel] = useState<any>(false)
  const [cookies, setCookie] = useCookies<any>([]);
   
  const loading = false;
  const navigate = useNavigate()
  

    const handleSubmit = async() => {
    
         const data = { phone, password}

            
         if(password === "" || phone=== ""){

            setErr("fill all field ")
        }else{
          
          try {
              // const token = Cookies.get(TOKEN_STORAGE_KEY);
              // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
              setErr("")
              const res =  await axios.post("https://store.moxieus.tech/login", data);
            
                if(res.data.state === 0){
                   
                     setErr(res.data.message)
                }else{
                     
                    if (res.data.state != 3) {
                        setCookie('user',res.data.data[0])
        
                         if(res.data.data[0].role === "user"){
                            navigate("/home")
                         }else{
                            navigate("/admin")
                         }
                        
                    }else{
                        setErr('Your Password is Incorrect, click  "Am New" to Continue')
                         setHuel(!Huel)
                    }
                }
              
          } catch (error) {
  
              // if (response.status === 401) {
                 
              // }
        }
      }
    }
     return (
    
    <div>
            <div className="max-h-screen bg-gray-50 flex-col justify-center">
                <div className="max-w-md w-full mx-auto">

                    <div className="text-3xl font-bold text-gray-900 mt-2 text-center">Opal Comm</div>
                </div>

                <div className="max-w-md w-full mx-auto mt-4 bg-white p-8 border border-gray-300">
                    <div className="space-y-6">
                        <div className="text-red-700">
                              {Err}
                        </div>
                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Mobile Number</label>
                            <input type="text" name="email" onChange={(e) => setPhone(e.target.value)} className="w-full p-2 border border-gray-300 rounded mt-1" />
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Password</label>
                            <input type="password" onChange={(e) => setPassword(e.target.value)} name="password" className="w-full p-2 border border-gray-300 rounded mt-1" />
                        </div>
                       
                        <div>
                         {
                            Huel ? 
                            <> 
                        <div className=" text-orange-400  ml-[18em]">
                                <a href={`/new?${phone}`}> ?
                                Am New
                                </a>
                                </div>
                                </>

                                :
                                <>

                                </>
                         }
                            </div>
                            <button  onClick={handleSubmit} className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm">Login</button>
                        </div>

                    </div>

            </div>
        </div>
     )

}

export default LoginForm