import React from "react";
import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react"

import Sidenav from '../Layout/Sidenav'
import Salebox from "./Salebox";
import SaleHistory from "./SaleHistory";
import CustomerReport from "./CustomerReport";



 
 function SaleFront (props:any) {
     

      useEffect(() => {
        // fetchProduct()

      }
      )
     

     return (
    
    <div className="mx-2 md:mx-0">
            <div className=" mt-2 md:mx-2 md:ml-6 md:flex ">
                
               {/* cut here  */}
           
                <Salebox />

                <div className=" mt-2  ml-2  md:flex space-x-2 space-y-6 md:space-y-0">
                  <CustomerReport/>
                    <SaleHistory />

                            <Sidenav  handleToggle={props.toggleup}/>
                </div>

            </div>


   
            
              

        </div>
     )

}

export default SaleFront