
import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";
import { SmallSpinner } from "../../Component/Layout/spinner"
import { useLocation } from "react-router-dom";
import { Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';




 function New () {

    const location = useLocation()
    const Loco  = location.search.split('?')[1]
      const [Num, setNum] = useState<any>(Loco)
      const [Cpassword, setCPassword ] = useState("")
      const [password, setPassword ] = useState("")
      const [err, setErr] = useState("")
      const [cookies, setCookie] = useCookies<any>([]);
     
    
      const loading = false;
       const navigate = useNavigate()
       const handleSubmit = async () => {
         
        const data = { Num, Cpassword, password}
          if(Cpassword=== "" || password  === "" ){
            
              setErr("fill all field before ")
          }else{
                setErr('')
              if(password === Cpassword) {
                

                try {
                    const res =  await axios.post("https://store.moxieus.tech/setpassword", data);
                    if (res.status === 200) {
                         console.log(res.data)
                        setCookie('user', res.data.user)
                         navigate("/home")
                    }
                } catch (error) { console.log(error)}
                
              }else{

              setErr('Password Do Not Match')
              }
            
        }

         
       }

    
     return (
    
    <div>
            <div className="max-h-screen bg-gray-50 flex-col justify-center">
                <div className="max-w-md w-full mx-auto">

                    <div className="text-3xl font-bold text-gray-900 mt-2 text-center">Opal Comm</div>
                </div>
                    <div className="">
                        <p className="text-red">{err}</p>
                    </div>

                    
                <div className="max-w-md w-full mx-auto mt-4 bg-white p-8 border border-gray-300">
                    <div  className="space-y-6">
                    <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Mobile Number</label>
                            <input type="text" name="email" disabled className="w-full p-2 border border-gray-300 rounded mt-1" value={Loco} onChange={(e) => setNum(e.target.value)} required />
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">password</label>
                            <input type="password" name="text" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setPassword(e.target.value)} required />
                        </div>

                      

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block"> Confirm Password</label>
                            <input type="password" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setCPassword(e.target.value)}  required/>
                        </div>
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={handleSubmit}>Continue<SmallSpinner show={loading} /></button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
     )

}

export default New