import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"

 function SaleFront (props:any) {

    return (
               <>
               <div className="md:hidden mt-10 h-40  menubtn right-4 bottom-0 fixed">
                    <div className="rounded-full text-white bg-blue-600 w-[3.5em] h-[3.5em] flex text-center " onClick={props.handleToggle}>
               
                    <span className="text-[40px] font-bold ml-[12px] mt-[-10px]">...</span>
          
                         
                    </div>

               </div>
               
                        <div className=" md:flex hidden absolute  right-0  h-[30em] ">
                        <div className="p-2 space-y-6">
                        
                        <div className="rightnav border-[1px]">
                        <IoReceiptSharp className="righticon" />
                            <span className="p-2 text-[16px] font-serif ml-4">Reciept</span>
                       </div>

                       
                        <div className="rightnav border-[1px]">
                        <MdAccountBalance className="righticon" />
                            <span className="p-2 text-[16px] font-serif ml-4">Account</span>
                       </div>

                        <div className="rightnav border-[1px]">
                        <RiCustomerService2Fill className="righticon" />
                            <span className="p-2 text-[16px] font-serif">Customer Desk</span>
                       </div>

                       <div className="rightnav border-[1px]">
                       <RiExchangeFill className="righticon" />
                          <span className="p-2 text-[16px] font-serif ml-2">Stock</span>
                       </div>

                       <div className="rightnav border-[1px]">
                       <ImHistory className="righticon " />
                            <span className="p-2 text-[16px] font-serif">Sale History </span>
                       </div>

                       <div className="rightnav border-[1px]">
                       <AiFillSetting className="righticon " />
                            <span className="p-2 text-[16px] font-serif ml-4">Preference </span>
                       </div>
                        
                        </div>
                    </div>
                    </>
)

}

export default SaleFront