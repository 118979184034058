import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Adduser from './Component/Pages/Adduser';
import Dashboard from './Component/Pages/Dashboard';
import LoginForm from './Component/Pages/LoginForm';
import Register from './Component/Pages/Register';
import Salehome from './Component/Pages/Salehome';
import Adminpage from './Component/Pages/Adminpage';
import Receiptpage from './Component/Pages/Receipt';
import AdminHomePAge from './Component/Pages/AdminHomePAge';
import Management from './Component/Pages/Management';
import { data } from './Component/Layout/chart';
import AddProduct from './Component/Layout/AddProduct';
import Categories from './Component/Layout/SetCategory';
import { useEffect, useState } from "react"
import AddUser from './Component/Layout/Adduser';
import New from './Component/Pages/New';
import EditProduct from './Component/Layout/EditProduct';


function App() {
   let AddprodComponent ;
   let categorie ;
   let user;
   let editProd ;
   const [Addprod, setAddprod] = useState(false)
   const [cat, setcat] = useState(false)
   const [adduser, setAdduser] = useState(false)
   const [editproduct, setEditProduct] = useState(false)
 

    const AddProdu = () => {
           setAddprod(!Addprod)
    }
    

    
const AddCat =()=> {
  setcat(!cat)
}

const Addusers =()=> {
  setAdduser(!adduser)
}

const Editproduct =()=> {
  
    setEditProduct(!editproduct)
}

if(editproduct) {
  editProd = <EditProduct callout={Editproduct} />
}

  
   if(adduser){
     user = <AddUser Swit={Addusers} />
   }

     if(Addprod){
       AddprodComponent = <AddProduct setSwit={AddProdu} />
     }

   // set categories  
     if(cat){
      categorie =  <Categories  catback={AddCat} />
     }

  
  return (
     
    <div>
      

     {AddprodComponent }
     {categorie}
     { user}
     { editProd}
      <BrowserRouter>
        <Routes>
         
          <Route path='/register' element={<Register />} />
          <Route path="/" element={<LoginForm />} />
            <Route path="home" element={<Salehome />} />
           <Route path="new" element={<New />} />
          <Route path='admin' element={<Adminpage  />} >
          <Route index element={<AdminHomePAge Users={Addusers}/>} />
            <Route path="user" element={<Adduser />} />
            <Route path="receipt" element={<Receiptpage/>}/>
            <Route path="mgt" element={<Management  Swit={AddProdu} editp={Editproduct} catgory={AddCat}/>}/>
          </Route>

        </Routes>
      </BrowserRouter>


    </div>
  );
}

export default App;
