import {product }  from '../../items'
import { useEffect, useState } from "react"
import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import axios from "axios";
import MiniSecAdminNav from '../Widgets/MiniSecAdminNav';
import BackDrop from '../Widgets/Backdrop';

type Products = typeof product


 function Management(props:any) {
  console.log(props)
 const [storeitem, setStoreItems] = useState<any>([])
 const [sorteditem, setSorteditem] = useState<any>([])
 const [ datas, setdate] = useState('')
 const [ minisidebar, setMiniSidebar] = useState(false)

    useEffect(() => {
        fetchProduct()
        fetchSorting()
    })

     const handlesidebar = () => {
          setMiniSidebar(!minisidebar)
     }
    let num
    let backdrop ;

      if(minisidebar){
         backdrop = <BackDrop  clickBack={handlesidebar} />
      }

     const fetchProduct = async()=> {
        const res = await axios.get("https://store.moxieus.tech/product")
        setStoreItems(res.data.data)
         
     }

     const fetchSorting = async()=> {
        const res = await axios.get("https://store.moxieus.tech/getsorting")
        setSorteditem(res.data.data)
         
     }

     

    const [items, setItems] = useState<Products>([])
     const [products, setProduct] = useState<Products>([])
     return (
        <>
        {backdrop}
    
        <MiniSecAdminNav Swit={props.Swit} edit={props.editp} toggle={minisidebar} handle={handlesidebar} category={props.catgory}/>
           <div className='md:flex '>
            <div className='md:w-[50em] ml-4'>
            <div  className="sm:px-8">

            <div className='md:flex'>
     <h3 className='text-sm'>[Available product]</h3>
    <input type="text" placeholder="search" className='md:ml-[16em] rounded-md border-gray-600 border-[1px] h-10 md:w-[22em]'  />
</div>
<div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mx-2">

<div className="table-wrp block max-h-[32em]">

<table className="md:w-full overflow-y-auto overflow-hidden">
<thead className="bg-white border-b-[1px] sticky top-0">
<tr className="text-sm font-bold  h-10">
  <th className=" text-left ">S/N</th>
  <th className="text-left  ">Items</th>
 <th className=" text-left ">Quantity</th>
 <th className="text-left  ">Cost Price</th>
  <th className=" text-left ">Price</th>
  <th className=" text-left ">Rack Position</th>
  <th className=" text-left ">...</th>
</tr>
</thead>

<tbody className=" h-[27em] overflow-y-auto">

{
                       
                       storeitem.length > 0 && 
                       storeitem.map((item:any, index:number) => 
                        
                       <>
                       
                        <tr className="text-sm h-10 tablelisting "  >
                      
                   <td className=" border-b-[1px] px-2 ">#</td>
                   <td className=" border-b-[1px] px-2">{item.name}</td>
                    <td className="border-b-[1px] ">{item.quantity}</td>
                    <td className="border-b-[1px] ">{item.costprice}</td>
                    <td className="border-b-[1px]"> ₦{item.saleprice}</td>
                    <td className="border-b-[1px]"> {item.position}</td>
                    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>edit</span></td>

                      </tr>
                       </>

                      
                       
                       
                       )
                       
                   } 


</tbody>
</table>
</div>


</div>
</div>
        </div>
        <div className='border-l-2 w-[21.3em]'>
            
            <div className='flex item-center  border-b-2 '>
            <h2 className='mt-2 ml-20'> [stock summmary]</h2>
            </div>
             {/* sorting section  */}
            <div className='p-2'>
               <h1 className='text-xs mt-4 text-blue-600 ml-2'>Your Product is Categories as follow:</h1>
               <div className='grid gap-2 grid-cols-3  grid-flow-row'>
                   
               {
                                    sorteditem.map((item:any) => 
                                    <div className='rounded-lg border-blue-500 border-2 bg-blue-500 text-white'>
                                   <div className='rounded-lg border-blue-500 border-2 text-white'>     
                     <div className='p-2 text-sm flex justify-center text-black font-semibold '>
                        <p> {item.name}</p>
                         </div>
                         </div>
                     <div className='p-2 text-sm flex justify-center mt-[-18px]'>
                         <p className=''>[{item.position}]</p>
                         </div>
                </div>

                                    )
                                }
               
               </div>
            </div>
            {/* balance stock */}
            <div className='border-2'>
                <div className='grid gap-2 grid-cols-2  grid-flow-row p-2'>
                <input type="text" className="w-full p-2 border border-gray-300 rounded mt-1" placeholder="date" />
            <select className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setdate(e.target.value)}>
                            <option value="null">Select Categories</option>
                            <option value="null">Select Categories</option>
                            <option value="null">Select Categories</option>
                            </select>
                            <div>
                                <button className='rounded-md bg-blue-600  text-white mt-10'>
                                    <p className='p-2'> Check </p>
                                </button>
                            </div>
                            <div className='text-xs font-semibold space-y-[2.3px]'>
                            <div className='bg-blue-600 text-white rounded-md'>
                               <p className='p-[1px]'> Total item Sold : 340</p>
                                </div>
                            <div className='bg-yellow-600 text-black rounded-md'>
                               <p className='p-[1px]'>Total sell made : 340</p>
                                </div>
                            <div className='bg-gray-600 text-white rounded-md'>
                                <p className="p-[1px]">Total Expenses : 340 </p>
                                </div>
                            <div className='bg-yellow-600 text-black rounded-md'> 
                            <p className='p-[1px] '>Net profit : 450 </p>
                            </div>

                            <div className='bg-red-800 text-white rounded-md'> 
                                <p className='p-[1px]'>Net Lost: 900</p>
                                 </div>
                            </div>
                 
                 
                 

                </div>
                        </div>
                     {/* other section  */}
                        <div className=''>

                           <div className='flex justify-center'>
                           <h2 className='text-sm ml-8 mt-4 font-semibold'>Total available product</h2>
                           </div>
                            <div className='flex justify-center'>
                            <h2 className='text-lg font-extrabold'>
                                3509
                            </h2>
                            </div>
                        </div>
        </div>

         {/* mini-width screen size */}
         <div className="md:hidden mt-10 h-40  menubtn right-4 bottom-0 fixed">
                    <div className="rounded-full text-white bg-blue-600 w-[3.5em] h-[3.5em] flex text-center " onClick={handlesidebar}>
               
                    <span className="text-[40px] font-bold ml-[12px] mt-[-10px]">...</span>
          
                         
                         
                    </div>

               </div>

               {/* large screen size */}

        <div className='border-2 hidden md:flex'>
        <div className="absolute  right-0  h-[30em] ">
                            <div className="p-2 space-y-6">
                        
                            <div className="rightnav border-[1px] " onClick={props.Swit}>
                            <IoReceiptSharp className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Add Product</span>
                           </div>
                           

                           
                            <div className="rightnav border-[1px]" 
                             onClick={ () => {
                                props.editp()
                               
                            }
                                }
                             
                            >
                            <MdAccountBalance className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Edit product</span>
                           </div>

                            <div className="rightnav border-[1px]">
                            <RiCustomerService2Fill className="righticon" />
                                <span className="p-2 text-[16px] font-serif">Mark Product</span>
                           </div>

                           <div className="rightnav border-[1px]">
                           <RiExchangeFill className="righticon" />
                              <span className="p-2 text-[16px] font-serif ml-2">Price Setting</span>
                           </div>

                           <div className="rightnav border-[1px]" onClick={props.catgory}>
                           <ImHistory className="righticon " />
                                <span className="p-2 text-[16px] font-serif">Add Sort </span>
                           </div>

                       
                            
                            </div>
                        </div>
        </div>

         </div>
        
        </>
     )
 }


 export default Management