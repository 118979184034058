import exp from "constants";
import React from "react";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";
import SaleFront from "../Widgets/SaleFront";
import AdminFront from "../Widgets/AdminFront"
import { Link, Outlet } from "react-router-dom"

 function Adminpage () {
     return (
    
    <div>
        <Navbar/>
        
        <Footer/>
        <Outlet />
            
        </div>
     )

}

export default Adminpage