import axios from 'axios'
import { useState, useEffect } from 'react'
import { SmallSpinner } from './spinner'

function AddUser(props:any) {

    const [Name, setName ] = useState("")
    const [email, setemail ] = useState("")
    const [phone, setPhone ] = useState("")
    const [address, setAddress ] = useState("")
    const [Sorting, setSorting]  = useState<any>([])
    const [Error, setError] = useState('')

     useEffect(() => {
        GetCatgories()

     },[Sorting])
     
      const GetCatgories = async() => {
        const res = await axios.get("http://stores.moxieus.co/getsorting")
        setSorting(res.data.data)
      }
    const handleSubmit = async() => {
         const createdby = "admin"
      const data = { Name, email, phone,address}
      if(Name === "" || email=== "" || phone  === "" || address === ""){
        setError("fill all field before submission ")
    }else{
      
      try {
          // const token = Cookies.get(TOKEN_STORAGE_KEY);
          // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

              setError('')
            const res =  await axios.post("http://stores.moxieus.co/add-user", data);
            if (res.status === 200) {
                console.log(res)
              }
          
      } catch (error) {

          // if (response.status === 401) {
             
          // }
    }
  }
    }
    

   
     const loading = true;

  return (
      <div className="w-full h-[100%] backdrop fixed top-0 bottom-0 left-0" >

          <div className='md:ml-[50em] ml-[20em] md:mt-[20px] mt-[2.8em] absolute bg-white rounded-full w-[30px] h-[30px] items-center  hover:bg-blue-600 hover:text-white text-red-500' onClick={props.Swit} >
             <span className='text-[22px]  ml-2'>X</span>
          </div>
          <div className=" mx-4 md:mx-0 md:w-[30em] md:mt-[50px] mt-[5em] bg-white md:ml-[20em] rounded-lg  ">
              <h3 className='ml-10 mt-10'>Add User</h3>
              <div className="max-w-md w-full mx-auto mt-4 bg-white p-4 border border-gray-300">
                    <div  className="space-y-4">
                        <div className=''>
                            <p className="text-red-600"> {Error} </p>
                        </div>
                    <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Name</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1"  onChange={(e) => setName(e.target.value)} required />
                        </div>
                        

                        <div>
                           
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Email</label>
                            <input type="email" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setemail(e.target.value)} />
                            

                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Phone Number</label>
                            <input type="number" name="Phone" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setPhone(e.target.value)}  required/>
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Address</label>
                            <input type="text" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setAddress(e.target.value)}  required/>
                        </div>
                       
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={() =>{
                                props.Swit()
                                handleSubmit()
                            }
                                }>Add User
                            {/* <SmallSpinner show={loading} /> */}
                            </button>
                        </div>

                    </div>
                </div>

          </div>
          <div className='h-10'>

          </div>

      </div>
  )
}

export default AddUser