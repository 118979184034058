import React from "react";
import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import { useEffect, useState } from "react"
import {product }  from '../../items'
import { type } from "@testing-library/user-event/dist/type";
import Sidenav from '../Layout/Sidenav'


type Products = typeof product



 
 function Receipt () {



    
     
     const [items, setItems] = useState<Products>([])
     const [products, setProduct] = useState<Products>([])

     console.log("from abuchi!",products);

      
      const AddItems =(item: any) => {
          console.log(item)
           setProduct([...products,item])
      }

      const removeItem = (id: any) => {
        //   setProduct(
        //       prev => {
        //           const new_item = prev.products.filter(({id}) => _id !== id)
        //       }
        //   )
          

      } 
      let no = 1
      useEffect(() => {
         setItems(product)

      }
      )

     

     return (
    
    <div>
            
            <div className="">
                <input type="text" placeholder="Item Name" />
                {/* <input type="text" placeholder="" />  */}
            </div>
        </div>
     )

}

export default Receipt