import { useEffect, useState } from "react"
import axios from "axios";
import { product } from "../../items";


 


 function Salebox (){

    const [items, setItems] = useState<any>([])
     const [products, setProduct] = useState<any>([])
     const [storeitem, setStoreItems] = useState<any>([])
     const [search, setSearch] = useState('')
     const [qtys, setQty] = useState<any>("1")
     const [searchdiv, setSearchdiv] = useState('')
     const [name, setqueryName] = useState('')
     const [total, setTotal] = useState<any>([])
     
     useEffect(() => {
           
         CalTotal()
     },[products])

      //total price selectd item
        let totalSum

    if(total){
        totalSum = total.reduce((a:any, b:any) => a+b, 0)
    }
     
      

     
     
    //  calculate total
    const CalTotal =()=> {
        products.map((items:any) => {
            let saleprice =  parseInt(items.salep)
            let salequan =  parseInt(items.saleqty)
            let totalsum = salequan * saleprice
          setTotal((oldtotal:any) => [...oldtotal, totalsum])
           
       })
    }
    
    
    
     const itemExist = (name:string) => {
         if(products.length > 1){
            const index =  products.filter((x: { name: string; }) => x.name != name)
            console.log("existing name", index)
            
         }
     }

     const AddItem = async(id: any,name:any,quantity:any,price:any) => {
        setSearchdiv('')
         let item = {id:id, item:name, qty:quantity,salep:price, saleqty:qtys}         
            setProduct((olditem:any)=>[...olditem, item])
            itemExist(name)
            setqueryName(name)
            setQty('1')
      } 


    //  remove items
      const removeItem = (id: any) => {
          console.log(products)
       const index =  products.filter((x: { id: any; }) => x.id != id)
       setProduct(index)
     } 
      
     const HandleSearch = async ()=> {
         setSearchdiv("start")
        const searchResult = await axios.get(`https://store.moxieus.tech/search?name=${search}`)
        setStoreItems(searchResult.data.item)

      }
        

     return(
    <div className="h-[31.8em]  border-2  mt-[10px] md:ml-4 ml-2">
    <div className="flex">
    <div className="bg-blue-500 h-10 w-40 ">
        <h2 className="text-lg  italic p-1 absolute text-white">Shop Items</h2>
    </div>
     
    </div>
    <div className="border-b-[1px] p-2">
        <input type="" className="h-10 rounded-lg md:w-[26.8em]  border-[1px] border-gray-500 " placeholder="Search for an item" onChange={(e) => setSearch(e.target.value)} onKeyDown={HandleSearch}/>
        {
            searchdiv ?
                        <>      
                                <div className=" ml-[-10px] z-50 absolute mx-2 md:mx-0 border-4 md:w-[26.8em] rounded-md bg-gray-200 h-[10em] overflow-auto">
                            <table>
                                <tbody className="">
                            {
                                
                                storeitem.length > 0 && storeitem.map((item:any, index:any) => 
                                 
                                <>
                                <tr className="text-sm h-10 tablelisting " key={item.id} >
                        <td className=" border-b-[1px] border-white px-2 border-r-2  md:w-[13em]">{item.name}</td>

                        <td className="border-b-[1px] border-white border-r-2">   
                         <input type="number"  onChange={(e) => setQty(e.target.value) } className="text-black text-sm w-10"/>
                         </td>                            
                        <td className="border-b-[1px] border-white border-r-2">₦{item.saleprice}</td>
                            <td className="border-b-[1px] border-white border-r-2 w-[6em]  justify-center">
                                <button className="  rounded-md text-xs bg-yellow-600 text-white ">
                                    <span className="p-2">{item.sortingname}</span>
                                </button>
                            </td>
                            <td className="border-b-[1px] border-r-2 text-xs hover:bg-red-400 p-2 border-white ">
                                
                                { item.quantity < 1 ? 

                            <span>Out of stock</span>

                            :

                            <span>Available</span>
                                }
                            
                            </td>
                            <td className="border-b-[1px] border-r-2 text-xs " onClick={()=>{
                                    AddItem(item.id,item.name,item.quantity,item.saleprice)
                                }}>
                                    <span className="p-2 hover:bg-red-400 rounded-md border-white bg-blue-500 text-white">Add</span>
                            </td>

                            </tr>

                            
                                </>
                                
                            
                                ) 
                            }
                           
                            
                            </tbody>
                            </table>
                                </div>
                        </>
            :
                <>
                </>

    }
    
    </div>

<div  className="sm:px-8">
<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">

<div className="table-wrp block max-h-[18.0em]">
<table className="md:w-[30.8em] w-[22.5em] overflow-y-auto overflow-hidden">
<thead className="bg-white border-b  top-0">
<tr className="text-sm font-bold border-b-[1px] h-10">
<th className=" text-left px-2">Items</th>
<th className=" text-left">Qty</th>
<th className="text-left ">Price/unit</th>
<th className="text-left ">...</th>

</tr>
</thead>
<tbody className=" h-[10em] overflow-y-auto">

{
       
    products.length > 0 && 
       products.map((item:any, index:any) => 
       
       <>

        <tr className="text-sm h-10 tablelisting">

   <td className=" border-b-[1px] px-2">{item.item}</td>
    <td className="border-b-[1px] ">
       {item.saleqty}
    </td>
    <td className="border-b-[1px]">#{item.salep}</td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 " onClick={() => removeItem(item.id)}><span>x</span></td>

      </tr>
       </>

      
    
       )
       
   } 


</tbody>
</table>
</div>


</div>
</div>


        
    {/* end table */}







    <div className=" mt-1">
        <div className=" md:fixed md:bottom-[9em]">
            <div className="absolute ml-0 p-2 mt-[-10px] ">
                <span className="text-xs font-bold">
                    Mode of Payment 
                </span><br/>
            <select className="w-40 h-10 text-sm">
                <option>Select Payment type</option>
                <option>Cash</option>
                <option>Transfer</option>
            </select>
            <div className="flex mt-2">
            <span className="text-xs font-bold mt-2">
                    Number
                </span><br/>
            <input type="text" className=" border-2 w-40 h-8 text-sm ml-2" />
                
        
            </div>
            
            </div>
            <div className="md:ml-[12em] ml-40">
            <span className="ml-6 text-sm font-bold">Total Price: {totalSum}.00</span>
            <button className="border-[1px] bg-blue-500 rounded-full w-20 h-10 absolute mt-10 ml-[-45px]">
            <span className="p-2 text-white font-bold ">Print</span>
            </button>
            </div>
        </div>
        
    </div>

    
</div>
     )
}

 export default Salebox