import exp from "constants";
import { useEffect, useState} from "react";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";
import SaleFront from "../Widgets/SaleFront";
import AdminFront from "../Widgets/AdminFront"
import MiniAdminFirstNav from "../Widgets/MiniAdminFirstNav";

import { MdAccountBalance, MdFacebook, MdIosShare } from "react-icons/md";
import { IoReceiptSharp }  from "react-icons/io5"
import {RiCustomerService2Fill} from "react-icons/ri"
import {RiExchangeFill} from "react-icons/ri"
import {AiFillSetting} from "react-icons/ai"
import {ImHistory} from "react-icons/im"
import BackDrop from "../Widgets/Backdrop";


 function AdminHomePAge (props:any) {
     
      const [moremenu, setMoremenu] = useState(false)

       const handlemenu = () => {
          setMoremenu(!moremenu)
       }

        let backdrop;

         if(moremenu){
             backdrop =  <BackDrop clickBack={handlemenu} />
         }
       
     return (
    
    <div>
        
       
        <AdminFront  adduser={props.Users} menus={handlemenu} />
    
            <MiniAdminFirstNav  adduser={props.Users} toggle={moremenu} handle={handlemenu}/>
             {backdrop}
{/* 
             mininav for wide screen */}
        
    
               
                        <div className=" md:flex hidden absolute  right-0  h-[30em] top-20 ">

                        <div className="p-2 space-y-6">
                            <a href="/admin/mgt" onClick={props.handle}>
                            <div className="rightnav border-[1px]">
                            <IoReceiptSharp className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Stock Management</span>
                           </div>
                           </a>

                           
                            <div className="rightnav border-[1px]" onClick={ () => {
                                props.Users()
                    
                            }
                                } >
                            <MdAccountBalance className="righticon" />
                                <span className="p-2 text-[16px] font-serif ml-4">Create user</span>
                           </div>

                            <div className="rightnav border-[1px]">
                            <RiCustomerService2Fill className="righticon" />
                                <span className="p-2 text-[16px] font-serif">Customer Desk</span>
                           </div>

                           <div className="rightnav border-[1px]">
                           <RiExchangeFill className="righticon" />
                              <span className="p-2 text-[16px] font-serif ml-2">Transaction</span>
                           </div>

                           <div className="rightnav border-[1px]">
                           <ImHistory className="righticon " />
                                <span className="p-2 text-[16px] font-serif">Sale History </span>
                           </div>

                           <div className="rightnav border-[1px]">
                           <AiFillSetting className="righticon " />
                                <span className="p-2 text-[16px] font-serif ml-4">Preference </span>
                           </div>
                            
                            </div>

                    </div>
        </div>
    
     )

}

export default AdminHomePAge