import { useEffect, useState } from "react"

function  SaleHistory () {
     

    useEffect(() => {
      // fetchProduct()

    }
    )
   

   return (
  
  <div>
          <div className="border-[1px] w-[20em] h-[32em]">
          <div className="bg-blue-500 h-10 w-40">
        <h2 className="text-lg  italic p-1 absolute text-white">Sale History</h2>
    </div>  
    <div className="">
         date 
    </div>

    {/* table */}
    <div  className="sm:px-8">
<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">

<div className="table-wrp block max-h-[30.0em]">
<table className="w-[20em] overflow-y-auto overflow-hidden">
<thead className="bg-white border-b sticky top-0">
<tr className="text-sm font-bold border-b-[1px] h-10">
<th className=" text-left px-2">S/n</th>
<th className=" text-left">product</th>
<th className="text-left ">qty</th>
<th className="text-left ">amount</th>

</tr>
</thead>
<tbody className=" h-[10em] overflow-y-auto">

        <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
    Iphone earpod
    </td>
    <td className="border-b-[1px]">2</td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>40000</span></td>
      </tr>
      
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">2</td>
    <td className="border-b-[1px] ">
       car charger
    </td>
    <td className="border-b-[1px]">3 </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>400</span></td>
      </tr>
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">3</td>
    <td className="border-b-[1px] ">
    screen touch
    </td>
    <td className="border-b-[1px]"> 4</td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>1500</span></td>
      </tr>
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">4</td>
    <td className="border-b-[1px] ">
         xr screen 
    </td>
    <td className="border-b-[1px]">5 </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>3000</span></td>
      </tr>

      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       Casing
    </td>
    <td className="border-b-[1px]">4 </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>7000</span></td>
      </tr>

      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">6</td>
    <td className="border-b-[1px] ">
    iphone charger
    </td>
    <td className="border-b-[1px]">3 </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>5000</span></td>
      </tr>
    

    


</tbody>
</table>
</div>


</div>
</div>
        

          </div>


 
          
            

      </div>
   )

}

export default SaleHistory