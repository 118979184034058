import { Textarea } from "flowbite-react"
import { useEffect, useState } from "react"

function  CustomerReport () {
     

    useEffect(() => {
      // fetchProduct()

    }
    )
   

   return (
  
  <div>
          <div className="border-[1px] md:w-[18em] mx-2">

          <div className="bg-blue-500 h-10 w-40">
        <h2 className="text-lg  italic p-1 absolute text-white">Report</h2>
    </div> 

    <div className="">
        {/* table */}
    <div  className="sm:px-8">
<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">

<div className="table-wrp block max-h-[8.0em]">
<table className="w-[18em] overflow-y-auto overflow-hidden">
<thead className="bg-white border-b sticky top-0">
<tr className="text-sm font-bold border-b-[1px] h-10">
<th className=" text-left px-2">S/n</th>
<th className=" text-left">Summary</th>
<th className="text-left ">Forms</th>
<th className="text-left ">...</th>

</tr>
</thead>
<tbody className=" h-[10em] overflow-y-auto">

        <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>
      
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>
      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>

      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>

      <tr className="text-sm h-10 tablelisting">
   <td className=" border-b-[1px] px-2">1</td>
    <td className="border-b-[1px] ">
       from abuchi 
    </td>
    <td className="border-b-[1px]">Item not in </td>
    <td className="border-b-[1px] text-xs hover:bg-red-400 p-2 "><span>x</span></td>
      </tr>
    

    


</tbody>
</table>
</div>


</div>
</div>

    </div>
   {/* report box */}
    <div className="p-2 border-[1px] mx-2 w-[16em] ml-6">
        <div className="">
        <h2 className="text-xs font-medium underline">Report box</h2>
        <div className=" mt-2">
            <label className="text-[12px]">All About your Report</label>
        <textarea className="w-[12em]"  placeholder="All about">
        </textarea>
        </div>

        <div className="">
        <label className="text-[12px]">Report on </label>
            <select className="w-[12em] h-8"> 
                <option>select Report</option>
                <option>Product</option>
                <option>Supply</option>
                <option>Customer</option>
                <option>Others</option>
            </select>
        </div>

        <div className="">
        <label className="text-[12px]">product Category</label>
            <select className="w-[12em] h-8"> 
                <option>select Report</option>
                <option>Product</option>
                <option>Supply</option>
                <option>Customer</option>
                <option>Others</option>
            </select>
        </div>

        <div className="">
        <label className="text-[12px]">Customer Receipt Id </label>
            <input type="text" placeholder="receipt id" className="w-[12em] h-8 border-[2px]"/>
        </div>

        <div className="mt-2 flex justify-center">

            <input type="button" value="Submit"  placeholder="receipt id" className="w-[8em] h-8 border-[2px]  bg-blue-500 text-white rounded-md" />
        </div>
        </div>


    </div>

          </div>


 
          
            

      </div>
   )
}

export default CustomerReport