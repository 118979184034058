import axios from 'axios'
import { useState, useEffect } from 'react'
import { product } from '../../items'
import { SmallSpinner } from './spinner'

function EditProduct(props:any) {
    const [id, setId ] = useState("")
    const [itemName, setitemName ] = useState("")
    const [itemPrice, setitemPrice ] = useState("")
    const [itemSalePrice, setitemSalePrice ] = useState("")
    const [itemCategory, setitemCategory ] = useState("")
    const [itemQty, setitemQty ] = useState("")
    const [showinput, setShowInput ] = useState("")
    const [Sorting, setSorting]  = useState<any>([])
    const [Error, setError] = useState('')
    const [search, setSearch] = useState('')
    const [searchdiv, setSearchdiv] = useState('')
    const [storeitem, setStoreItems] = useState<any>([])
     
    const [Product, setProduct] = useState<any>("")


     useEffect(() => {
        GetCatgories()

     },[Sorting])
     
      const GetCatgories = async() => {
        const res = await axios.get("https://stores.moxieus.co/getsorting")
        console.log("mmsmsmmm",res)
        setSorting(res.data.data)
      }
    const handleSubmit = async() => {
         const createdby = "admin"
      const data = { itemName, itemPrice, itemSalePrice,itemQty, itemCategory, createdby}
      if(itemName === "" || itemCategory=== "" || itemPrice  === "" || itemSalePrice === "" || itemQty  === ""){
        setError("fill all field before submission ")
    }else{
      
      try {
          // const token = Cookies.get(TOKEN_STORAGE_KEY);
          // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
          
          if(parseInt(itemSalePrice) > parseInt(itemPrice)){
              setError('')
            const res =  await axios.post("https://stores.moxieus.co/add-items", data);
            if (res.status === 200) {
              
                console.log(res)
              }
          }else{
              setError("Sale Price can be less than cost price")
          }
      } catch (error) {

          // if (response.status === 401) {
             
          // }
    }
  }
    }

    const HandleSearch = async ()=> {
        setSearchdiv("start")
       const searchResult = await axios.get(`https://stores.moxieus.co/search?name=${search}`)
       setStoreItems(searchResult.data.item)

     }

     const HandleEdit = (id:any,name:any,quantity:any,price:any,sortingname:any,costp:any
        ) => {
            setShowInput("some")
            setSearchdiv("")
            setSearch("")
            let item = {id:id, item:name, qty:quantity,costprice:costp, sortingname:sortingname,salep:price, saleqty:quantity} 
            setProduct(item)

     }


      console.log("Solomon ",Sorting)

    

   
     const loading = true;

  return (
      <div className="w-full h-[100%] backdrop fixed top-0 bottom-0 left-0" >

          <div className='md:ml-[50em] md:mt-[20px] ml-[20em] mt-[2em] absolute bg-white rounded-full w-[30px] h-[30px] items-center  hover:bg-blue-600 hover:text-white text-red-500' onClick={props.callout} >
             <span className='text-[22px]  ml-2'>X</span>
          </div>
          <div className="md:w-[30em] md:mt-[50px] mt-[5em]  bg-white md:ml-[20em] mx-4 md:mx-0 rounded-lg  ">
              <h3 className='ml-10 mt-10'>edit Product </h3>
              <div className="max-w-md w-full mx-auto mt-4 bg-white p-4 border border-gray-300">
                    <div  className="space-y-4">
                        <div className=''>
                            <p className="text-red-600"> {Error} </p>
                        </div>

                        <div>
                            
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1" placeholder='Search for product'  onChange={(e) => setSearch(e.target.value)} value={search} onKeyDown={HandleSearch} required />

                            {
            searchdiv ?
                        <>      
                                <div className=" ml-[-10px] z-50 absolute mx-2 md:mx-0 border-4 md:w-[26.8em] rounded-md bg-gray-200 h-[10em] overflow-auto">
                            <table>
                                <tbody className="">
                            {
                                
                                storeitem.length > 0 && storeitem.map((item:any, index:any) => 
                                 
                                <>
                                <tr className="text-sm h-10 tablelisting " key={item.id} onClick={ () => {
                                 HandleEdit(item.id,item.name,item.quantity,item.saleprice,item.sortingname, item.costprice)
                                }} >
                        <td className=" border-b-[1px] border-white px-2 border-r-2  md:w-[13em]">{item.name}</td>
                            
                        <td className="border-b-[1px] border-white border-r-2">{item.quantity}</td>
                            <td className="border-b-[1px] border-white border-r-2 w-[6em]  justify-center">
                                <button className="  rounded-md text-xs bg-yellow-600 text-white ">
                                    <span className="p-2">{item.sortingname}</span>
                                </button>
                            </td>
                            <td className="border-b-[1px] border-r-2 text-xs hover:bg-red-400 p-2 border-white ">
                            ₦{item.saleprice}
                            </td>
        

                            </tr>

                            
                                </>
                                
                            
                                ) 
                            }
                           
                            
                            </tbody>
                            </table>
                                </div>
                        </>
            :
                <>
                </>

    }
                        </div>

                    {
                      showinput ? 
                      <>
                      <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Item Name</label>
                            <input type="text" name="name" className="w-full p-2 border border-gray-300 rounded mt-1" value={Product.item}  onChange={(e) => setitemName(e.target.value)} required />
                        </div>
                        

                        <div>
                           
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Sort Type</label>
                            <select className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemCategory(e.target.value)}>
                            <option value={Product.sortingname}>{Product.sortingname}</option>
                                                        {
                                    Sorting.map((item:any) => 
                                        
                                        <option value={item.id}>{item.name}</option>
                                    )
                                }
                            
                              
                            </select>
                          
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Cost Price</label>
                            <input type="number" name="Phone"  value={Product.costprice} className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemPrice(e.target.value)}  required/>
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Sale price</label>
                            <input type="number" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" onChange={(e) => setitemSalePrice(e.target.value)}  value={Product.salep} required/>
                        </div>
                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Quantity</label>
                            <input type="number" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" value={Product.saleqty} onChange={(e) => setitemQty(e.target.value)}  required/>
                        </div>

                        <div className='hidden'>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block"></label>
                            <input type="number" name="password" className="w-full p-2 border border-gray-300 rounded mt-1" value={Product.id} onChange={(e) => setId(e.target.value)}   required/>
                        </div>
                      
                      </>
                      :
                      <>
                      </>
                    }
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={handleSubmit}>Update
                            {/* <SmallSpinner show={loading} /> */}
                            </button>
                        </div>

                    </div>
                </div>

          </div>
          <div className='h-10'>

          </div>

      </div>
  )
}

export default EditProduct