

export const product = [
    {
        id: 1,
        name: 'Wriast watch',
        qty: 40,
        price: 4000
    },
    {
        id: 2,
        name: 'Ear peace',
        qty: 30,
        price: 4000
    },
    {
        id: 3,
        name: 'Phone charger',
        qty: 20,
        price: 100
    },
    {
        id: 4,
        name: 'Wriast watch',
        qty: 6,
        price: 430
    },
    {
        id: 5,
        name: 'screen touch for pro',
        qty: 10,
        price: 2000
    },
    {
        id:6,
        name: 'Smart watch',
        qty: 2000,
        price: 20000
    },
    {
        id: 7,
        name: 'phone casing nice mon',
        qty: 5,
        price: 5000
    },
    {
        id: 8,
        name: 'screen',
        qty: 5,
        price: 5000
    },
    {
        id: 4,
        name: 'Wriast watch',
        qty: 6,
        price: 430
    },
    {
        id: 5,
        name: 'screen touch for pro ',
        qty: 10,
        price: 2000
    },
    {
        id:6,
        name: 'Smart watch',
        qty: 2000,
        price: 20000
    },
    {
        id: 7,
        name: 'phone casing nice mon',
        qty: 5,
        price: 5000
    },
    {
        id: 8,
        name: 'screen',
        qty: 5,
        price: 5000
    }
]