

import { useState, useEffect } from 'react'
import { SmallSpinner } from './spinner'
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import axios from "axios";


function Categories(props:any) {
   console.log("sleek", props)
    const [name, setName ] = useState("")
    const [position, setPosition ] = useState("")
    const [errors, setError ] = useState("")
    const [Sorting, setSorting] = useState([])
   
     
    const handleSubmit = async() => {
        const data = {name, position}
       
        if(name === "" || position=== "" ){
            setError("fill all field before ")
        }else{
          
          try {
              // const token = Cookies.get(TOKEN_STORAGE_KEY);
              // axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
              const res =  await axios.post("https://stores.moxieus.co/sort", data);
              if (res.status === 200) {
                
                // navigate("/admin/mgt")
              }
          } catch (error) {
  
              // if (response.status === 401) {
                 
              // }
        }
      }

    }


    useEffect(() => {
        GetCatgories()

     })
     
      const GetCatgories = async() => {
        const res = await axios.get("https://stores.moxieus.co/getsorting")
        setSorting(res.data.data)
      }

     const loading = true;

  return (
      <div className="w-full h-[100%] backdrop fixed top-0 bottom-0 left-0" >

<div className='md:ml-[50em] md:mt-[20px] ml-[20em] mt-[2em] absolute bg-white rounded-full w-[30px] h-[30px] items-center  hover:bg-blue-600 hover:text-white text-red-500' onClick={props.catback} >
             <span className='text-[22px]  ml-2'>X</span>
          </div>
          <div className="md:w-[30em] md:mt-[50px] mt-[5em]  bg-white md:ml-[20em] mx-4 md:mx-0 rounded-lg">
              <h3 className='ml-10 mt-10'>Set Category</h3>
              <div className="max-w-md w-full mx-auto mt-4 bg-white p-4 border border-gray-300">
                  <div>

                  </div>

                    <div  className="space-y-4">
                      <p>Existing Sorts </p>
                      <div className='grid gap-2 grid-cols-4  grid-flow-row space-x-2 w-[20em] border-2'>
                          
                        {
                                    Sorting.map((item:any) => 
                                          <div className='w-10'>
                                        <p className='text-blue-600'>{item.name},</p>
                                        </div>
                                    )
                                
                                }
                          
                            </div>
                    <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Name your sorting</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1"  onChange={(e) => setName(e.target.value)} required />
                        </div>

                        <div>
                            <label htmlFor="" className="text-sm font-bold text-gray-600 block">Position/Rack Number</label>
                            <input type="text" name="email" className="w-full p-2 border border-gray-300 rounded mt-1"  onChange={(e) => setPosition(e.target.value)} required />
                        </div>
                       
                        <div>
                            <button className="w-full py-4 px-4 bg-blue-600 hover:bg-blue-700 rounded-md text-white text-sm" onClick={handleSubmit}>Add product
                            {/* <SmallSpinner show={loading} /> */}
                            </button>
                        </div>

                    </div>
                </div>

          </div>
          <div className='h-10'>

          </div>

      </div>
  )
}

export default Categories