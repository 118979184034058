import exp from "constants";

import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";
import BackDrop from "../Widgets/Backdrop";
import MinMenu from "../Widgets/MinMenu";
import SaleFront from "../Widgets/SaleFront";


 function Salehome () {
     const [menu, setMenu] = useState(false)

     const Minimenu = () => {
          setMenu(!menu)
     }
     let backdrop ;

     if(menu){
        backdrop = <BackDrop clickBack={Minimenu} />
     }
     


     return (
    
    <div>
        <Navbar/>
        {backdrop}
        <MinMenu toggle={menu} toggledown={Minimenu}/>
        <SaleFront toggleup={Minimenu}  />
        <Footer/>
            
        </div>
     )

}

export default Salehome