import exp from "constants";
import React from "react";

 function Footer () {
     return (
    
    <div className=" fixed inset-x-0 bottom-0  border-t-2 border-gray-500 bg-gray-50 ">
       <div className="flex items-center justify-center">
       <p className="text-blue-500">
           Developed by</p>
       </div>

       <div className="flex items-center justify-center">
           <h1 className="text-blue-500">Moxieus Technology</h1>
        
       </div>

       <div className="h-2">
        </div>
            
        </div>
     )

}

export default Footer